var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{ref:"table",attrs:{"hide-default-header":"","hide-default-footer":"","headers":_vm.mappedHeaders,"items":_vm.mappedRows,"items-per-page":_vm.mappedRows.length},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('span',[_vm._v("Keine Daten vorhanden")])]},proxy:true},{key:"header",fn:function(ref){
var props = ref.props;
return [_c('thead',[_vm._l(([_vm.processTableHeaders(props.headers)]),function(headers,i){return [_c('tr',{key:Math.random() + i},_vm._l((headers.parents),function(header,index){return _c('th',{directives:[{name:"show",rawName:"v-show",value:(header.description),expression:"header.description"}],key:index.toString(),attrs:{"rowspan":1,"colspan":header.descriptionColSpan}},[_vm._v(" "+_vm._s(header.description)+" ")])}),0),_c('tr',{key:Math.random() + i},_vm._l((headers.parents),function(header){return _c('th',{key:header.value,attrs:{"rowspan":header.rowspan,"colspan":header.colspan}},[_c('span',[_vm._v(" "+_vm._s(header.text)+" ")])])}),0),(headers.children)?_c('tr',{key:Math.random() + i + 1},_vm._l((headers.children),function(header,index){return _c('th',{key:Math.random() + header.value + index},[_c('span',[_vm._v(_vm._s(header.text))])])}),0):_vm._e()]})],2)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',_vm._l((item),function(ref,index){
var value = ref.value;
var header = ref.header;
return _c('td',{key:index + Math.random()},[(header === 'a_anbieter')?_c('p',[_c('span',[_vm._v(_vm._s(_vm.replaceAnbieterData(value).bankName))])]):_c('div',[_c('div',[(
                header.includes(
                  'neben_zinsrabattaufschlag_maximaldarlehen'
                ) && _vm.banksData(value) === '0'
              )?_c('span',[_vm._v("unbegrenzt")]):_c('span',[_vm._v(_vm._s(_vm.banksData(value)))])])])])}),0)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }