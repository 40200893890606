













































































import { Component, Prop, Vue } from 'vue-property-decorator';

import { BACKEND_URL, COLOR } from '@/utils/constants';
import { numberWithCommas } from '../../../../../wettbewerbsvergleich/src/utils/helpers';

@Component({
  components: {},
  computed: {},
})
export default class FMHAdvancedTableForXLS extends Vue {
  @Prop({ default: () => [] }) headers?: object[];
  @Prop({ default: () => [] }) rows?: object[];

  url: String = BACKEND_URL;
  color: String = COLOR;

  get mappedHeaders() {
    return this.headers;
  }
  get mappedRows() {
    return this.rows;
  }

  validate(header, val, compare) {
    if (header && compare?.hasOwnProperty(`${header}_difference`)) {
      return !(
        val === 0 ||
        val === '0' ||
        !val ||
        val === '-' ||
        Number.isNaN(+val)
      );
    }
  }

  banksData(val) {
    if (val === '99999') {
      return '-';
    } else {
      if (val.includes('ab')) {
        return val;
      } else {
        return numberWithCommas(val);
      }
    }
  }

  processTableHeaders(headers) {
    const nested = !!headers.some((h) => h.children);
    if (nested) {
      let children: object[] = [];

      const h = headers.map((h) => {
        const copy = { ...h, isDynamic: false };
        if (
          !copy.value.includes('produkt') &&
          !copy.value.includes('anbieter')
        ) {
          Object.assign(copy, { isDynamic: true });
        }
        if (copy.children && copy.children.length > 0) {
          const last = copy.children.length - 1;
          const modifiedChildren = copy.children?.map((item, index) => {
            let o = {};
            if (
              !item.value.includes('produkt') &&
              !item.value.includes('anbieter')
            ) {
              Object.assign(o, item, { isDynamic: true });
            }
            if (index === 0) {
              Object.assign(o, item, {
                className: 'border-child-left border-right-child-small',
              });
            }
            if (last === index) {
              Object.assign(o, item, { className: 'border-child-right' });
            }
            if (index !== 0 && last !== index) {
              Object.assign(o, item, { className: 'border-right-child-small' });
            }
            return o;
          });
          children.push(...modifiedChildren);
          return {
            description: copy?.description || '',
            rowspan: 1,
            colspan: copy.children.length,
            text: copy.text,
          };
        }
        return {
          description: copy?.description || '',
          rowspan: 2,
          colspan: 1,
          text: copy.text,
        };
      });
      const filtered = children.filter(
        (item) => !(item as any).value.includes('produkt')
      );

      const modifiedHeaders = h.map((header) => {
        if (header.description.includes('Anbieter')) {
          return {
            ...header,
            descriptionColSpan: 1,
          };
        }
        if (header.description.includes('Produkt')) {
          return {
            ...header,
            descriptionColSpan: header.colspan,
          };
        }
        return {
          ...header,
          descriptionColSpan: filtered.length,
        };
      });
      return {
        children: children,
        parents: modifiedHeaders,
      };
    }
    return {
      parents: headers,
    };
  }
  replaceAnbieterData(val) {
    if (val) {
      const url = val.split('|')[1];
      const bankName = val.split('|')[2];
      return {
        url,
        bankName,
      };
    }
    return { url: '', bankName: '' };
  }
}
