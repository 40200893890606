










































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapActions, mapGetters, mapMutations, MutationMethod } from 'vuex';

import Button from '@/components/fmh_components/common/Buttons/Button.vue';
import IconButton from '@/components/fmh_components/common/Buttons/IconButton.vue';
import GeneralTable from '@/components/fmh_components/common/GeneralTable/GeneralTable.vue';
import Loader from '@/components/fmh_components/common/Loader/Loader.vue';
import Error from '@/components/fmh_components/common/Error/Error.vue';
import FMHAdvancedTable from '@/components/fmh_components/common/FMHAdvancedTable/FMHAdvancedTable.vue';
import DotsMenu from '@/components/fmh_components/common/DotsMenu/DotsMenu.vue';
import DateSelection from '@/components/fmh_components/common/DateSelection/DateSelection.vue';
import ModalConfirm from '../../../wettbewerbsvergleich/src/components/common/ModalConfirm/ModalConfirm.vue';
import NoteTagsHint from '@/components/fmh_components/common/NoteTagsHint/NoteTagsHint.vue';
import FMHAdvancedTableForXLS from '@/components/fmh_components/common/FMHAdvancedTableForXLS/FMHAdvancedTableForXLS.vue';
import SnackBar from '@/components/fmh_components/common/SnackBar/SnackBar.vue';

import { mappedInterestCredit } from '@/utils/helpers/helpers';
import {
  BASE_BLACK_COLOR,
  INTEREST_CREDIT_FESTGELD,
  TEXT_ACTION_BUTTONS,
} from '@/utils/constants';
import {
  getType,
  exportXLS,
  headersForCSV,
  rowsForCSV,
  savePDF,
} from '@/utils/helpers';

interface reportData {
  slug: String;
  headers: headerValue[];
  rows: Array<Object>;
}

interface allReportsData {
  slug: String;
  headers: headerValue[];
  rows: Array<Object>;
}

interface headerValue {
  value: String;
  text: String;
}

interface RequiredPayload {
  url: String;
  title: String;
  note: String;
  meta: Object;
  tags: Array<string>;
  slug: String;
  uid: String;
  allBanks: Array<string>;
}

interface RequiredPayloadLatest {
  url: String;
}

interface RequiredPayloadXLSAllFields {
  newId: String;
  oldId: String;
}

interface RequiredPayloadCompare {
  newId: String;
  oldId: String;
  title: String;
  note: String;
  meta: Object;
  tags: Array<string>;
  slug: String;
  uid: String;
  config: Object;
}

@Component({
  components: {
    FMHAdvancedTableForXLS,
    DateSelection,
    DotsMenu,
    FMHAdvancedTable,
    IconButton,
    Button,
    GeneralTable,
    Loader,
    Error,
    ModalConfirm,
    NoteTagsHint,
    SnackBar,
  },
  computed: {
    ...mapGetters({
      error: 'reports/error',
      loading: 'reports/loading',
      singleReport: 'reports/singleReport',
      uploadReport: 'reports/uploadReport',
      updatedReportsTagesgeld: 'reports/updatedReportsTagesgeld',
      updatedReportsFestgeld: 'reports/updatedReportsFestgeld',
      updatedReportsBaugeld: 'reports/updatedReportsBaugeld',
      allReportsFestgeld: 'reports/allReportsFestgeld',
      allReportsTagesgeld: 'reports/allReportsTagesgeld',
      allReportsBaugeld: 'reports/allReportsBaugeld',
      dateNowBaugeld: 'dateNow/getDateNowBaugeld',
      dateNowTagesgeld: 'dateNow/getDateNowTagesgeld',
      dateNowFestgeld: 'dateNow/getDateNowFestgeld',
      reportType: 'reports/report_type',
      isAuthorized: 'auth/isAuthorized',
      snackbarData: 'reports/getSnackbarData',
      user: 'auth/getUser',
    }),
  },
  methods: {
    ...mapActions({
      fetchSnapshots: 'reports/fetchSnapshots',
      deleteReport: 'reports/deleteReport',
      fetchSingleReport: 'reports/fetchSingleReport',
      compareReports: 'reports/compareReports',
      fetchLatestDataOfTheReport: 'reports/fetchLatestDataOfTheReport',
      updateExistingReport: 'reports/updateExistingReport',
      fetchXLSAlFields: 'reports/fetchXLSAlFields',
    }),
    ...mapMutations({
      setSnackBarData: 'reports/setSnackBarData',
      deleteReportSuccess: 'reports/fetchDeleteReportSuccess',
      changeUploadReport: 'reports/uploadReportSuccess',
      fetchSingleReportSuccess: 'reports/fetchSingleReportSuccess',
      setError: 'reports/error',
      updateReport: 'reports/updateReport',
      resetSingleReport: 'reports/resetSingleReport',
    }),
  },
})
export default class NewReport extends Vue {
  [x: string]: any;
  color: String = BASE_BLACK_COLOR;
  productType: String = this.$router.currentRoute.params.producttype;
  dialog: Boolean = false;
  isLoadingFile: Boolean = false;
  isLoadingLatestData: Boolean = false;
  isCompare: Boolean = false;
  isLatestData: Boolean = false;
  date: String = '';
  tableStyle: Object = {
    width: '100%',
  };
  isShowNewNote = false;
  isCreatedNewNote = false;
  newNote = '';
  singleReport!: reportData;
  existingReport: any;
  allReportsTagesgeld!: Array<allReportsData>;
  allReportsFestgeld!: Array<allReportsData>;
  allReportsBaugeld!: Array<allReportsData>;
  uploadReport!: Boolean;
  updatedReportsTagesgeld!: Boolean;
  updatedReportsFestgeld!: Boolean;
  updatedReportsBaugeld!: Boolean;
  isAuthorized!: Boolean;
  dateNowFestgeld!: String;
  dateNowTagesgeld!: String;
  dateNowBaugeld!: String;
  menuItems: any[] = [];
  dateMit = '';
  dateVom = '';
  currentHeadline = '';
  currentNote = '';
  currentTags = [];
  isCreatedNewTags = false;
  isEditTags = false;
  isShowNewTags = false;
  isShowEditTags = false;
  isEditNote = false;
  isShowEditTitle = false;
  isShowEditNote = false;
  titleRules: any[] = [];
  isEdit = false;
  isDeleting = false;
  textActionButtons = TEXT_ACTION_BUTTONS;
  headersForXLS: object[] = [];
  rowsForXLS: object[] = [];
  newTags = [];

  setError!: MutationMethod;
  deleteReport!: Function;
  setMenuItems!: Function; //mixin
  updateMenuItemsModel!: Function; //mixin
  deleteReportSuccess!: MutationMethod;
  resetSingleReport!: MutationMethod;
  fetchSnapshots!: (payload: RequiredPayload) => Promise<Object>;
  compareReports!: (payload: RequiredPayloadCompare) => Promise<Object>;
  fetchXLSAlFields!: (payload: RequiredPayloadXLSAllFields) => Promise<Object>;
  fetchLatestDataOfTheReport!: (
    payload: RequiredPayloadLatest
  ) => Promise<Object>;
  setSnackBarData!: MutationMethod;

  get isShowNoteTags() {
    if (!this.error && !this.loading) {
      return setTimeout(() => {
        return true;
      });
    }
    return false;
  }
  @Watch('singleReport') set() {
    this.menuItems = this.setMenuItems(this.singleReport, 'singleReport');
    this.dateVom = this.$date(
      (this.singleReport as any)?.meta?.data_time
    ).format('DD.MM.YYYY');
  }
  @Watch('uploadReport') setValues(val) {
    if (val) {
      this.menuItems = this.setMenuItems(this.singleReport, 'singleReport');
      this.dateVom = this.$date(
        (this.singleReport as any)?.meta?.data_time
      ).format('DD.MM.YYYY');
    }
  }
  @Watch('loading') setStyles(val) {
    if (!val) {
      setTimeout(() => {
        this.setStylesForTable();
      }, 100);
    }
  }

  @Watch('isAuthorized') fetchReports(val: boolean) {
    if (val) {
      if (
        this.allReportsTagesgeld.length === 0 &&
        this.allReportsFestgeld.length === 0 &&
        this.allReportsBaugeld.length === 0
      ) {
        this.fetchSingleReport(this.$router.currentRoute.params.slug);
        this.date = `${this.$date(new Date())}`;
      }
      if (
        this.updatedReportsTagesgeld ||
        this.updatedReportsFestgeld ||
        this.updatedReportsBaugeld
      ) {
        this.date = `${this.$date(new Date())}`;
        this.fetchSingleReport(this.$router.currentRoute.params.slug);
      }
    }
  }

  get defaultStyle() {
    const height = window.innerHeight - 276;
    return {
      'min-height': `${height}px`,
    };
  }
  get renderDate() {
    if ((this.content as any)?.meta?.data_time) {
      return this.$date((this.content as any).meta.data_time).format(
        'DD.MM.YYYY'
      );
    }
    return this.$date(this.date).format('DD.MM.YYYY');
  }
  get dateRangeText() {
    return `${this.dateVom} ~ ${this.dateMit}`;
  }
  get type() {
    return getType(this.productType);
  }
  get validateMetaForDate() {
    return !!(
      (this.content as any)?.meta &&
      (this.content as any).meta?.availableData?.length === 1
    );
  }
  get validateEdit() {
    return !!(
      (this.content as any)?.meta && (this.content as any).meta?.editable
    );
  }
  get validateMetaForPicker() {
    return !!(
      (this.content as any)?.meta &&
      (this.content as any).meta?.availableData?.length > 1
    );
  }
  get content() {
    if (this.uploadReport) {
      return this.singleReport ? this.singleReport : {};
    } else {
      return this.existingReport ? this.existingReport : {};
    }
  }
  get fileName() {
    if ((this.content as any)?.title) {
      return (this.content as any).title
        .replace(/[^a-zA-ZßäüöÄÖÜ0-9,.\s]/gi, ' ')
        .replace(/^\s+|\s+$/g, '')
        .replace(/\s+/g, '_');
    }
  }
  get csvFileName() {
    const date = this.dateForXLS()
      .replace(/[^a-zA-ZßäüöÄÖÜ0-9,.\s]/gi, ' ')
      .replace(/^\s+|\s+$/g, '')
      .replace(/\s+/g, '_');
    return `${this.fileName}_${date}`;
  }
  get headersCSV() {
    return headersForCSV(this.content, Number(this.reportType));
  }
  get rowsCSV() {
    return rowsForCSV(this.content, this.dataInterestCredit, this.$date);
  }
  updateHeadersForXLS(headers) {
    this.headersForXLS = headers;
  }
  updateRowsForXLS(rows) {
    this.rowsForXLS = rows;
  }
  async goOverview() {
    await this.$router.push(`/${this.productType}`);
    this.existingReport = {};
    this.resetSingleReport();
  }
  changeVisibilityNewNote() {
    this.isShowNewNote = !this.isShowNewNote;
  }
  changeVisibilityNewTags() {
    this.isShowNewTags = !this.isShowNewTags;
  }
  handleChangeNewNote(val) {
    this.newNote = val;
  }
  handleChangeNewTags(val) {
    this.newTags = val;
  }
  handleChangeCurrentNote(val) {
    this.currentNote = val;
  }
  handleChangeCurrentTags(val) {
    this.currentTags = val;
  }
  handleChangeConfirmModal(val) {
    this.dialog = val;
  }

  openMenu(title) {
    this.menuItems = this.updateMenuItemsModel(title, this.menuItems, true);
  }
  closeMenu(title) {
    localStorage.setItem('hint', 'false');
    if (this.isShowEditNote) {
      this.currentNote = '';
      this.isShowEditNote = false;
    }
    if (this.isShowNewNote) {
      this.isShowNewNote = false;
      this.newNote = '';
    }
    this.menuItems = this.updateMenuItemsModel(title, this.menuItems);
  }
  onEditTitle() {
    this.currentHeadline = (this.content as any).title;
    this.isShowEditTitle = true;
  }
  onEditNote() {
    this.currentNote = (this.content as any).note;
    this.isShowEditNote = true;
  }
  onEditTags() {
    this.currentTags = (this.content as any).tags;
    this.isShowEditTags = true;
  }
  updateHeadline() {
    this.titleRules = [(v) => !!v || 'Titel ist erforderlich'];
    setTimeout(async () => {
      if ((this.$refs.form as any).validate()) {
        this.isEdit = true;
        try {
          const payload = {
            slug: (this.content as any).slug,
            uid: (this.content as any).uid,
            title: this.currentHeadline,
            report_type: +this.type,
            is_temporary: false,
            meta: (this.content as any).meta || {},
            rows: (this.content as any).rows || [],
            headers: (this.content as any).headers || [],
            tags: (this.content as any).tags || [],
            note: (this.content as any).note || '',
            allBanks: (this.content as any).allBanks || [],
            field: 'title',
            config: {
              ...(this.content as any).config,
              selection_title: this.currentHeadline,
            },
          };
          await this.updateExistingReport(payload);

          const { field, ...rest } = payload;
          this.updateReport(rest);
          this.currentHeadline = '';
          this.titleRules = [];
          this.isShowEditTitle = false;
          this.isEdit = false;
        } catch (e) {
          this.currentHeadline = '';
          this.titleRules = [];
          this.isShowEditTitle = false;
          this.isEdit = false;
        }
      }
    });
  }
  createTags() {
    setTimeout(async () => {
      if (this.isShowNewTags) {
        this.isCreatedNewTags = true;
        try {
          const payload = {
            meta: (this.content as any).meta || {},
            rows: (this.content as any).rows || [],
            headers: (this.content as any).headers || [],
            slug: (this.content as any).slug,
            uid: (this.content as any).uid,
            title: (this.content as any).title,
            report_type: +this.type,
            is_temporary: false,
            tags: this.newTags || [],
            note: (this.content as any).note || null,
            config: (this.content as any).config,
            field: 'tags',
          };
          await this.updateExistingReport(payload);

          const { field, ...rest } = payload;
          this.updateReport({
            ...rest,
            allBanks: (this.content as any).allBanks || [],
          });
          this.newTags = [];
          this.isShowNewTags = false;
          this.isCreatedNewTags = false;
        } catch (e) {
          this.newTags = [];
          this.isShowNewTags = false;
          this.isCreatedNewTags = false;
        }
      }
    }, 100);
  }
  createNote() {
    setTimeout(async () => {
      if (this.isShowNewNote) {
        this.isCreatedNewNote = true;
        try {
          const payload = {
            meta: (this.content as any).meta || {},
            rows: (this.content as any).rows || [],
            headers: (this.content as any).headers || [],
            slug: (this.content as any).slug,
            uid: (this.content as any).uid,
            title: (this.content as any).title,
            report_type: +this.type,
            is_temporary: false,
            tags: (this.content as any).tags || [],
            note: this.newNote || null,
            config: (this.content as any).config,
            field: 'note',
          };
          await this.updateExistingReport(payload);

          const { field, ...rest } = payload;
          this.updateReport({
            ...rest,
            allBanks: (this.content as any).allBanks || [],
          });
          this.newNote = '';
          this.isShowNewNote = false;
          this.isCreatedNewNote = false;
        } catch (e) {
          this.newNote = '';
          this.isShowNewNote = false;
          this.isCreatedNewNote = false;
        }
      }
    }, 100);
  }
  updateTags() {
    setTimeout(async () => {
      if (this.isShowEditTags) {
        this.isEditTags = true;
        try {
          const payload = {
            slug: (this.content as any).slug,
            uid: (this.content as any).uid,
            title: (this.content as any).title,
            report_type: +this.type,
            is_temporary: false,
            meta: (this.content as any).meta || {},
            rows: (this.content as any).rows || [],
            headers: (this.content as any).headers || [],
            tags: this.currentTags || [],
            note: (this.content as any).note || null,
            config: (this.content as any).config,
            field: 'tags',
          };
          await this.updateExistingReport(payload);
          const { field, ...rest } = payload;
          this.updateReport({
            ...rest,
            allBanks: (this.content as any).allBanks || [],
          });
          this.currentTags = [];
          this.isShowEditTags = false;
          this.isEditTags = false;
        } catch (e) {
          this.currentTags = [];
          this.isShowEditTags = false;
          this.isEditTags = false;
        }
      }
    }, 100);
  }
  updateNote() {
    setTimeout(async () => {
      if (this.isShowEditNote) {
        this.isEditNote = true;
        try {
          const payload = {
            slug: (this.content as any).slug,
            uid: (this.content as any).uid,
            title: (this.content as any).title,
            report_type: +this.type,
            is_temporary: false,
            meta: (this.content as any).meta || {},
            rows: (this.content as any).rows || [],
            headers: (this.content as any).headers || [],
            tags: (this.content as any).tags || [],
            note: this.currentNote || null,
            config: (this.content as any).config,
            field: 'note',
          };
          await this.updateExistingReport(payload);
          const { field, ...rest } = payload;
          this.updateReport({
            ...rest,
            allBanks: (this.content as any).allBanks || [],
          });
          this.currentNote = '';
          this.isShowEditNote = false;
          this.isEditNote = false;
        } catch (e) {
          this.currentNote = '';
          this.isShowEditNote = false;
          this.isEditNote = false;
        }
      }
    }, 100);
  }
  async getLatestDataOfTheReport() {
    this.isCompare = false;
    this.isLoadingLatestData = true;
    this.isLatestData = true;
    this.dateVom = this.$date(new Date()).format('DD.MM.YYYY');
    this.dateMit = '';
    try {
      const response = await this.fetchLatestDataOfTheReport({
        url: this.$router.currentRoute.params.slug,
      });
      const result = Object.assign((response as any).data, {
        title: (this.content as any).title,
        note: (this.content as any)?.note || '',
        tags: (this.content as any)?.tags || [],
        uid: (this.content as any).uid,
        slug: (this.content as any).slug,
      });
      this.fetchSingleReportSuccess({
        ...result,
        allBanks: (this.content as any).allBanks || [],
      });
      this.isLoadingLatestData = false;
      this.isLatestData = false;
    } catch (e) {
      this.setError(true);
    }
  }

  setStylesForTable() {
    setTimeout(() => {
      const windowWeight = window.innerWidth - 410;
      const contentWeight = (this.$refs?.document as Vue)?.$el?.scrollWidth;
      if (contentWeight > windowWeight) {
        return (this.tableStyle = {
          width: `${contentWeight}px`,
        });
      }
    });
  }

  handleChangeDateMit(val) {
    this.dateMit = this.$date(val).format('DD.MM.YYYY');
    this.isCompare = true;
    const newId = (this.content as any).meta.availableData.find(
      (item) => item.date === this.dateVom.split('.').reverse().join('-')
    ).slug;
    const oldId = (this.content as any).meta.availableData.find(
      (item) => item.date === val
    ).slug;

    this.compareReports({
      newId,
      oldId,
      title: (this.content as any).title,
      note: (this.content as any)?.note || '',
      meta: (this.content as any)?.meta || {},
      tags: (this.content as any)?.tags || [],
      slug: (this.content as any).slug,
      config: (this.content as any).config,
      uid: (this.content as any).uid,
    });
  }
  handleChangeDateVom(val) {
    this.isCompare = false;
    this.dateVom = this.$date(val).format('DD.MM.YYYY');
    const obj = (this.content as any).meta.availableData.find(
      (item) => item.date === val
    );
    this.fetchSnapshots({
      url: obj.url,
      title: (this.content as any).title,
      note: (this.content as any)?.note || '',
      meta: (this.content as any)?.meta || {},
      tags: (this.content as any)?.tags || [],
      slug: (this.content as any).slug,
      uid: (this.content as any).uid,
      allBanks: (this.content as any)?.allBanks || [],
    });
    if (this.dateMit) {
      this.dateMit = '';
    }
  }
  openDialog() {
    if (this.user?.isDemoUser && this.content?.is_preconfigured) {
      this.setSnackBarData({
        text: this.$t('warnings.demoUserDeletingReport'),
        show: true,
        timeout: 3000,
        dark: false,
        color: 'orange',
      });
    } else {
      this.dialog = true;
    }
  }
  dateForXLS() {
    if (this.dateMit && this.dateVom) {
      return `Vergleich ${this.dateVom} ~ ${this.dateMit}`;
    }
    //eslint-disable-next-line
    return (this.content as any)?.meta?.data_time ? `Es werden die Daten vom ${this.$date((this.content as any).meta.data_time).format('DD.MM.YYYY')} angezeigt` : `Es werden die Daten vom  ${this.$date(this.date).format('DD.MM.YYYY')} angezeigt`;
  }
  async saveXLS() {
    const date = this.dateForXLS();
    if (this.isCompare) {
      try {
        this.isLoadingFile = true;
        const newId = (this.content as any).meta.availableData.find(
          (item) => item.date === this.dateVom.split('.').reverse().join('-')
        ).slug;
        const oldId = (this.content as any).meta.availableData.find(
          (item) => item.date === this.dateMit.split('.').reverse().join('-')
        ).slug;
        const response: any = await this.fetchXLSAlFields({
          newId,
          oldId,
        });
        const headers = this.getHeadersForXLS(
          response.data.headers,
          response.data.rows
        );
        const rows = this.processTableRows(headers, response.data.rows);
        this.headersForXLS = headers;
        this.rowsForXLS = rows;
        setTimeout(() => {
          exportXLS((this.$refs?.documentXLS as Vue)?.$el, this.fileName, date);
          this.isLoadingFile = false;
        }, 200);
      } catch (e) {
        this.isLoadingFile = false;
      }
    } else {
      exportXLS((this.$refs?.documentXLS as Vue)?.$el, this.fileName, date);
    }
  }

  createPDF() {
    this.isLoadingFile = true;
    const dates = [this.dateVom, this.dateMit].filter((i) => i);
    setTimeout(() => {
      savePDF(
        (this.$refs.document as Vue)?.$el,
        this.fileName,
        dates,
        this.renderDate,
        this.dateRangeText,
        this.$date
      ).then(() => {
        this.isLoadingFile = false;
      });
    });
  }
  submitEditReport() {
    if (this.user.isDemoUser && this.content.is_preconfigured) {
      this.setSnackBarData({
        text: this.$t('warnings.demoUserEditingReport'),
        show: true,
        timeout: 3000,
        dark: false,
        color: 'orange',
      });
    } else {
      return this.$router.push(
        `/${this.productType}/wizard/${this.$router.currentRoute.params.slug}`
      );
    }
  }
  async deleteCurrentReport() {
    this.isDeleting = true;
    try {
      await this.deleteReport({
        slug: this.$router.currentRoute.params.slug,
        product_type: this.type,
      });
      this.deleteReportSuccess({
        slug: this.$router.currentRoute.params.slug,
        product_type: this.type,
      });
      this.isDeleting = false;
      this.dialog = false;
      await this.$router.push(`/${this.productType}`);
    } catch (e) {
      this.isDeleting = false;
      this.dialog = false;
      this.setError(true);
    }
  }
  dataInterestCredit(data) {
    if (this.type === '2') {
      return mappedInterestCredit(data, INTEREST_CREDIT_FESTGELD);
    }
    return mappedInterestCredit(data);
  }
  created() {
    if (this.isAuthorized) {
      if (
        this.productType === 'tagesgeld' &&
        this.allReportsTagesgeld.length !== 0
      ) {
        this.changeUploadReport(false);
        const searchReport = this.allReportsTagesgeld.find(
          (item) => item.slug === this.$router.currentRoute.params.slug
        );
        this.existingReport = searchReport ? searchReport : this.singleReport;
        this.date = this.dateNowTagesgeld;
        this.menuItems = this.setMenuItems(this.existingReport, 'singleReport');
        this.dateVom = this.$date(
          (this.existingReport as any)?.meta?.data_time
        ).format('DD.MM.YYYY');
      }
      if (
        this.productType === 'festgeld' &&
        this.allReportsFestgeld.length !== 0
      ) {
        this.changeUploadReport(false);
        const searchReport = this.allReportsFestgeld.find(
          (item) => item.slug === this.$router.currentRoute.params.slug
        );
        this.existingReport = searchReport ? searchReport : this.singleReport;
        this.date = this.dateNowFestgeld;
        this.menuItems = this.setMenuItems(this.existingReport, 'singleReport');
        this.dateVom = this.$date(
          (this.existingReport as any)?.meta?.data_time
        ).format('DD.MM.YYYY');
      }
      if (
        this.productType === 'baugeld' &&
        this.allReportsBaugeld.length !== 0
      ) {
        this.changeUploadReport(false);
        const searchReport = this.allReportsBaugeld.find(
          (item) => item.slug === this.$router.currentRoute.params.slug
        );
        this.existingReport = searchReport ? searchReport : this.singleReport;
        this.date = this.dateNowBaugeld;
        this.menuItems = this.setMenuItems(this.existingReport, 'singleReport');
        this.dateVom = this.$date(
          (this.existingReport as any)?.meta?.data_time
        ).format('DD.MM.YYYY');
      }
      if (
        this.productType === 'baugeld' &&
        this.allReportsBaugeld.length === 0
      ) {
        this.fetchSingleReport(this.$router.currentRoute.params.slug);
        this.date = `${this.$date(new Date())}`;
      }
      if (
        this.productType === 'tagesgeld' &&
        this.allReportsTagesgeld.length === 0
      ) {
        this.fetchSingleReport(this.$router.currentRoute.params.slug);
        this.date = `${this.$date(new Date())}`;
      }
      if (
        this.productType === 'festgeld' &&
        this.allReportsFestgeld.length === 0
      ) {
        this.fetchSingleReport(this.$router.currentRoute.params.slug);
        this.date = `${this.$date(new Date())}`;
      }
    }
  }
  mounted() {
    this.setStylesForTable();
  }
}
