








import { Component, Prop, Emit, Vue } from 'vue-property-decorator';

@Component
export default class IconButton extends Vue {
  @Prop() src!: String | undefined;

  @Emit('click') onSubmit() {
    return true;
  }
}
